import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

const init = () => {
    const swiper = new Swiper('.swiper-quotes', {
        speed: 400,
        spaceBetween: 60,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        autoHeight: true,
        autoplay:  {
            delay: 5000,
        },
        modules: [Navigation, Pagination, Autoplay],
    });
};

export default init;
