import Highcharts from 'highcharts';

export default function initStateDataChart(id = 'ncrc-chart-data') {
    const chartEl = document.getElementById(id)

    if(!chartEl) {
        return;
    }

    const chartDates = getStateChartDates();
    const chartData = getStateChartData();

    createChart(chartEl, chartDates, chartData);
}

function getStateChartDates() {
    let dates = [];
    let datesEl = document.getElementById('ncrc-chart-dates');
    datesEl?.querySelectorAll('i')?.forEach((date) => {
        dates.push(date.innerHTML.trim())
    });

    return dates;
}

function getStateChartData() {
    let data = [];
    let dataEl = document.getElementById('ncrc-chart-data');
    dataEl?.querySelectorAll('.row')?.forEach((item) => {
        let row = {};
        row.data = [];
        row.name = item.querySelector('.name')?.innerHTML?.trim() || '';
        row.color = item.querySelector('.color')?.innerHTML?.trim() || '';

        item.querySelectorAll('.data i')?.forEach((dataItem) => {
            var value = parseFloat(dataItem.innerHTML.trim());

            if (value < 0) {
                var value = 0;
            }

            row.data.push(value);
        });

        data.push(row);
    });

    return data;
}

function createChart(chartEl, dates, data) {
    let ncrc_chart = new Highcharts.Chart({
        chart: {
            renderTo: chartEl.id,
            type: 'bar',
            width: 680,
            height: 240,
            spacingTop: 20,
            className: 'ncrc-chart'
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        xAxis: {
            // alternateGridColor: '#EEEEEE',
            gridLineColor: '#DDDDDD',
            lineColor: '#DDDDDD',
            lineWidth: 5,
            categories: dates,
            labels: {
                formatter: function () {
                    return '<span style="font-size: 1.1em;">' + this.value + '</span>';
                }
            }
        },
        yAxis: {
            maxPadding: 0.25,
            title: {
                text: null
            },
            showEmpty: false,
            labels: {
                formatter: function () {
                    return '<span style="margin-right:20px">' + Highcharts.numberFormat(this.value, 0) + '</span>';
                },
                useHTML: true
            },
            gridLineColor: '#DDDDDD',
            lineColor: '#DDDDDD',
            lineWidth: 5
        },
        tooltip: {
            formatter: function () {
                return '<strong>' +
                    Highcharts.numberFormat(this.y, 0) + ' ' + this.series.name + '</strong> NCRC Certificates Earned in <strong>' +
                    this.x + '</strong>';
            }
        },
        plotOptions: {
            bar: {
                stacking: 'normal',
                fillOpacity: 1,
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 4,
                    states: {
                        hover: {
                            enabled: true
                        }
                    },
                    lineColor: '#000',
                    lineWidth: 1
                },
                lineWidth: 0
            }
        },
        series: data,
        legend: {
            reversed: true
        }
    });
}
