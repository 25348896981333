export default function piechart () {
    $('.pie-chart').easyPieChart({
        size: 260,
        barColor: "#8268A3",
        scaleLength: 0,
        lineWidth: 45,
        trackColor: "#63428C",
        lineCap: "circle",
        animate: 2000,
    });
}