export function setupModalToggles(selector) {
    let modals = document.querySelectorAll(selector);

    if(!modals) {
        return;
    }

    modals.forEach((modal) => {
        let modalId = modal.id;

        var openModal = function (e){
            if(e.target.dataset.openModal && e.target.dataset.openModal !== `#${modalId}`) {
                return;
            } 
        
            if(e.target.dataset.closeModal && e.target.dataset.closeModal !== `#${modalId}`) {
                return;
            } 
        
            if(e.target.dataset.toggleModal && e.target.dataset.toggleModal !== `#${modalId}`) {
                return;
            } 
        
            if(e.target.dataset.openModal) {
                e.preventDefault();
                openModal(modal);
            }
        
            if(e.target.dataset.closeModal) {
                e.preventDefault();
                closeModal(modal);
            }
        
            if(e.target.dataset.toggleModal) {
                e.preventDefault();
                toggleModal(modal);
            }
        };

        document.body.addEventListener('keydown', function(e) {
            if (e.keyCode === 27) {
                closeModal(modal);
            }
        });

        document.body.addEventListener('click', openModal);
        document.body.addEventListener('e.keyCode == 32', openModal);
    })
    
}

export function toggleModal(modal) {
    if(modal?.classList.contains('is-open')) {
        const iframe = modal?.querySelector('[data-src]');
        if(iframe) {
            iframe.src = '';

            window.setTimeout(() => {
                iframe.src = iframe.dataset.src;
            }, 5);
        }
    }

    modal?.classList.toggle('is-open');
}

export function openModal(modal) {
    modal?.classList.add('is-open');
}

export function closeModal(modal) {
    if(modal?.classList.contains('is-open')) {
        const iframe = modal?.querySelector('[data-src]');
        if(iframe) {
            iframe.src = '';

            window.setTimeout(() => {
                iframe.src = iframe.dataset.src;
            }, 10);
        }
    }
    modal?.classList.remove('is-open');
}

export function toggleModalById(modalId) {
    toggleModal(document.getElementById(modalId));
}

export function openModalById(modalId) {
    openModal(document.getElementById(modalId));
}

export function closeModalById(modalId) {
    closeModal(document.getElementById(modalId));
}
