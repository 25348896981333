import initTickers from './components/ticker';
import responsiveNav from './components/atd-responsive-nav';
import navClick from './components/navclick';
import initStateDataChart from './components/charts';
import Accordion from './components/accordion';
import slideShow from './components/slideShow';
import medallion from './components/medallion';
import certificates from './components/certificateChart';
import keyGrowthCharts from './components/keyGrowthCharts';
import quoteSliders from './components/quotesSlider';
import county_highlight from './components/countyhighlight';
import { setupModalToggles } from './components/Modal.js';
import { setupGooglePieCharts } from './components/GooglePieCharts.js';
import $ from 'jquery';
import piechart from './components/piechart';
import './vendor/ticker.js';
setupModalToggles('.modal-container');

const jQuery = $;

// make jquery accessable ouside of webpack compiled js
// DO NOT REMOVE, SUPER DUPER IMPORTANT
window.$ = $;
window.jQuery = jQuery;
require('./vendor/ticker');// MUST load after import of jquery
require('./vendor/jquery.easypiechart.js');
//Nav Click
navClick();

//Accordion
Accordion();

//County page state map
county_highlight();

//Responsive Nav
responsiveNav();

// init tickers
initTickers();

//Certificate Charts
certificates();

//Medallion
medallion();

//int State Data Chart
initStateDataChart();

//Homepage slide show
slideShow();

//Key Growth Charts
keyGrowthCharts();

//Quote Slider
quoteSliders();

//Animated Piechart
piechart();

// load google pie charts
window.addEventListener('load', () => {
  setupGooglePieCharts();
});
