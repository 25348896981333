function medallion(){
    var eco_animation = document.getElementById('ecosystem-animation');

        if (eco_animation) {
            var medallions = document.querySelectorAll('.medallion');

            medallions.forEach(function(medallion) {
                medallion.addEventListener('mouseenter', onMedallionHoverIn);
                medallion.addEventListener('mouseleave', onMedallionHoverOut);
            });

            var link_targets = document.querySelectorAll('[data-href]');
            link_targets.forEach(function(link_target) {
                let href = link_target.dataset.href;
                let target = link_target.dataset.target;

                if (target && target === '_blank') {
                    link_target.addEventListener('click', function(e) {
                        e && e.preventDefault();

                        window.open(href, '_blank');
                    });
                } else {
                    link_target.addEventListener('click', function(e) {
                        e && e.preventDefault();

                        window.location = href;
                    });
                }
            });

            function onMedallionHoverIn(e) {
                if (window.innerWidth < 980) {
                    return;
                }
                medallions.forEach(function(medallion) {
                    medallion.classList.add('out');
                });
                document.querySelector('.map-ecographic').classList.add('out');
                e.target.classList.remove('out');
            }

            function onMedallionHoverOut(e) {
                if (window.innerWidth < 980) {
                    return;
                }
                medallions.forEach(function(medallion) {
                    medallion.classList.remove('out');
                });
                document.querySelector('.map-ecographic').classList.remove('out');
            }
        }
}

export default medallion;