function navClick() {
    var navItem = document.querySelectorAll('.sub-nav');
    var openNavItem = null;

    navItem.forEach(function(item) {
        item.addEventListener('click', function (e) {
            closeAllSubNavs(e);
            item.classList.toggle('open');
            openNavItem = item;
            document.body.addEventListener('click', closeAllSubNavs);
        });
    });

    function closeAllSubNavs(e) {
        if (e) {
            var activeItem = document.querySelector('.sub-nav.open');
            if (activeItem && activeItem.contains(e.target)) {
                return;
            }
        }

        navItem.forEach(function(item) {
            if (item !== openNavItem) {
                item.classList.remove('open');
            }
        });

        document.body.removeEventListener('click', closeAllSubNavs);
    }

    document.addEventListener('keydown', function(e) {
        if (e.key === 'Escape') {
            closeAllSubNavs();
        }
    });
}

export default navClick;