function slideShow(){
    var slideIndex = 1;
    showSlides(slideIndex);

    function plusSlides(n) {
    showSlides(slideIndex += n);
    }

    function currentSlide(n) {
    showSlides(slideIndex = n);
    }

    function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if(!slides.length) {
        return;
    }

    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {slideIndex = 1}    
        if (n < 1) {slideIndex = slides.length}
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";  
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }

            if(slides[slideIndex - 1]) {
                slides[slideIndex-1].style.display = "flex";  
                dots[slideIndex-1].className += " active";
            }
        }
        window.onload= function () {
        setInterval(function(){ 
            plusSlides(1);
        }, 3000);
    }
}

export default slideShow;