function keyGrowthCharts(){
    var participating = document.querySelector('.participating-communities');
    var employers = document.querySelector('.employers-supporting');
    var job = document.querySelector('.job-profile');
    var participatingChart = document.querySelector('.participating-communities-chart');
    var employersChart = document.querySelector('.employers-supporting-chart');
    var jobChart = document.querySelector('.job-profile-chart');

    participating?.addEventListener('mouseenter', event => {
        participating?.classList.add('active');
        employers?.classList.remove('active');
        job?.classList.remove('active');
        participatingChart?.classList.add('active-chart')
        employersChart?.classList.remove('active-chart')
        jobChart?.classList.remove('active-chart')
      });

    employers?.addEventListener('mouseenter', event => {
        employers?.classList.add('active');
        participating?.classList.remove('active');
        job?.classList.remove('active');
        employersChart?.classList.add('active-chart')
        participatingChart?.classList.remove('active-chart')
        jobChart?.classList.remove('active-chart')
    });
    job?.addEventListener('mouseenter', event => {
        job?.classList.add('active');
        participating?.classList.remove('active');
        employers?.classList.remove('active');
        jobChart?.classList.add('active-chart')
        participatingChart?.classList.remove('active-chart')
        employersChart?.classList.remove('active-chart')
    });

}

export default keyGrowthCharts