export function setupGooglePieCharts() {
  if(!window.google?.charts) {
    console.error('Google charts not loaded');
    return;
  }
  window.google.charts.load("current", {packages:["corechart"]});
  window.google.charts.setOnLoadCallback(loadCharts);
};

function loadCharts() {
  const pies = document.querySelectorAll('[data-pie-chart]');
  for(let pie_el of pies) {
    loadChart(pie_el);
  }
}

function loadChart(pie_el) {
  let pieData = loadPieData(pie_el);
  if(!pieData?.data) {
    return;
  }

  var data = google.visualization.arrayToDataTable(pieData.data);
  var options = pieOptions[pieData.options || 'map'];
  var chart = new window.google.visualization.PieChart(pie_el);
  chart.draw(data, options);
}

function loadPieData(pie_el) {
  try {
    let pieData = JSON.parse(pie_el.dataset['pieChart']);
    return pieData;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const sharedOptions = {
    title: null,
    colors: ['#274970', '#C4C4C4'],
    legend: 'none',
    pieSliceText: 'none',
    enableInteractivity: false,
}

const pieOptions = {
  coin: {
    ...sharedOptions,
    slices: {
      0: { color: '#274970' },
      1: { color: 'transparent' }
    },
    chartArea:{left:58,top:16,width:146,height:146},
  },
  map: {
    ...sharedOptions,
    pieSliceBorderColor: '#C4C4C4',
    backgroundColor: 'transparent',
    chartArea:{left:0,top:0,width:48,height:48},
  },
}
