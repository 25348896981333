function certificates(){
    var plus = document.querySelector('.plus');
    var minus = document.querySelector('.minus');
    var workkeys = document.querySelector('.workkeys');
    var workkeysStats = document.querySelector('.workkeys-stats');
    var workkeysChart = document.querySelector('.workkeys-chart');

    plus?.addEventListener('click', event => {
        minus.classList.add('active-icon');
        plus.classList.remove('active-icon');
        workkeys.classList.add('active-chart');
        workkeysStats.classList.add('active-chart')
        workkeysChart.classList.remove('active-chart')
    });
    minus?.addEventListener('click', event => {
        plus.classList.add('active-icon');
        minus.classList.remove('active-icon');
        workkeys.classList.remove('active-chart');
        workkeysStats.classList.remove('active-chart')
        workkeysChart.classList.add('active-chart')
    });


}


export default certificates