function initTicker(element, defaultOptions) {
    let options = {
        number: 99999,
        height: 56.5,
        speed: 1500,
        ...defaultOptions
    }

    let observer = new IntersectionObserver((entries) => {
        let isIntersecting = false;
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                isIntersecting = true;
            }
        });

        if(!isIntersecting) {
            return
        }

        options.number = element.dataset.value ? parseInt(element.dataset.value) : options.number

        let ticnum = options.number;
        let fticnum = addCommas(ticnum);
        addTicker(element, fticnum);
        if (ticnum && ticnum != 0) {
            var s = String(fticnum);
            for (let i = s.length; i >= 0; i--) {
                var onum = s.charAt(i);
                for(let el of element.querySelectorAll(`div[rel='num${i}']`)) {
                    el.value = onum;
                }
            }

            for(let el of element.querySelectorAll(`.odoNumber`)) {
                var nval = el.value;
                if (!isNaN(nval)) {
                    var nheight = Number(nval) * options.height * -1;
                    el.style.top = nheight + 'px';
                    el.style.transitionDuration = `${options.speed / 1000}s`;
                }
                if (nval == ',') {
                    el.style.top = '-564px';
                    el.style.transitionDuration = `${options.speed / 1000}s`;
                }
            }
        }
    }, {
        threshold: 1.0
    });

    observer.observe(element);
}

export default function initTickers (selector = '.ticker', options) {
    const tickers = document.querySelectorAll(selector);
    for(let ticker of tickers) { 
        console.log(tickers)
        initTicker(ticker, options);
    }
}

function addTicker(element, newnum) {
    let digitcnt  = element.querySelectorAll('.odoNumber').length;
    var nnum = String(newnum).length;
    var digitdiff = Number(nnum - Number(digitcnt));
    if (digitdiff < 0) {
        var ltdig = (Number(nnum) - 1);
        for(let el of element.querySelectorAll(`.odoNumber:gt(${ltdig})`)) {
            el.remove();
        }
    }
    for (let i = 1; i <= digitdiff; i++) {
        element.appendChild('<div class="odoNumber" rel="num' + (Number(digitcnt + i - 1)) + '">&nbsp;</div>')
    }
}

function addCommas(nStr) {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    return x1 + x2
}
